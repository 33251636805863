<template>
  <v-dialog v-model="dialog" persistent max-width="380">
    <v-card>
      <v-card-title class="text-h5"> Impossibilidade de edição! </v-card-title>
      <v-card-text>
        <span class="error--text">Atenção!</span> Ao finalizar a avaliação, ela
        não poderá mais ser editada.
      </v-card-text>
      <v-card-text> Deseja continuar com está ação? </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="$emit('close-dialog')">
          Fechar
        </v-btn>
        <v-btn color="success" text @click="$emit('finish')">
          Finalizar avaliação
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalFinalizarAvaliacao.vue",
  props: {
    dialog: {
      type: Boolean,
      require: true,
      default: false,
    },
  },
};
</script>

<style></style>

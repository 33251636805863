<template>
  <section>
    <v-row v-if="!isShowStepper">
      <v-col cols="12">
        <v-stepper class="custom-header" :value="tabFormSelected">
          <v-stepper-header>
            <template v-for="(step, stepIndex) in tabsAvaliacaoFormFiltred">
              <v-stepper-step
                :step="stepIndex + 1"
                :key="`step-${stepIndex + 1}`"
                :complete="tabFormSelected > stepIndex + 1"
                v-if="step.disabled == false"
              >
                <div class="d-flex align-center">
                  <v-icon class="text--primary text-h4 me-2">
                    {{ step.icon }}
                  </v-icon>
                  <div class="d-flex flex-column">
                    <span class="text--primary text-sm font-weight-semibold">{{
                      step.name
                    }}</span>
                  </div>
                </div>
              </v-stepper-step>
              <v-divider
                :key="stepIndex"
                v-if="stepIndex < tabsAvaliacaoFormLength - 1"
              />
            </template>
          </v-stepper-header>
          <v-divider class="mt-6"></v-divider>
          <v-stepper-items>
            <component
              ref="componentRender"
              :is="tabsAvaliacaoFormFiltred[tabFormSelected - 1].component"
              @isUpdateTab="isUpdateValidateTab"
            />
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
    <!-- Botões -->
    <v-row>
      <v-col cols="12">
        <div class="d-flex flex-row align-center justify-end">
          <v-btn
            @click="walkSteps(false)"
            class="mx-2"
            color="secondary"
            outlined
            :disabled="tabFormSelected == 1 ? true : false"
          >
            <v-icon size="18" class="me-1"> mdi-arrow-left-thin </v-icon>
            <span>voltar</span>
          </v-btn>
          <v-btn
            v-if="tabFormSelected < tabsAvaliacaoFormLength"
            @click="walkSteps()"
            class="mx-2"
            color="accent"
          >
            <span class="mr-2">Salvar e continuar</span>
            <v-icon size="18" class="ma-0">
              mdi-checkbox-marked-circle-outline
            </v-icon>
          </v-btn>
          <v-btn @click="finish(true)" v-else class="mx-2" color="success">
            <span class="mr-2">Finalizar</span>
            <v-icon size="18" class="ma-0"> mdi-check </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-overlay :value="isShowStepper">
      <v-progress-circular indeterminate color="#001325" size="75">
        <img :src="image" height="45" alt="" />
      </v-progress-circular>
    </v-overlay>
    <ModalFinalizarAvaliacao
      :dialog="dialogFinishEvaluation"
      @close-dialog="dialogFinishEvaluation = false"
      @finish="finish()"
    />
  </section>
</template>

<script>
import moment from "moment";
import logo from "@/assets/images/logos/legiio.png";
import { ref, computed, watch, onMounted } from "@vue/composition-api";
import { required, emailValidator } from "@core/utils/validation";
import { getLaterDate, setDayLaterDate } from "@/helpers";
import store from "@/store";
import { useRouter } from "@core/utils";
import ModalFinalizarAvaliacao from "../components/ModalFinalizarAvaliacao.vue";

export default {
  name: "avd-configuracao-view",
  inject: ["$validator"],
  components: { ModalFinalizarAvaliacao },
  setup() {
    // Config
    moment.locale("pt-br");

    // LifeCyle
    onMounted(() => {
      const { route } = useRouter();
      let uuidAvd = route.value.path.split("/")[3];
      if (!avaliacao.value.id) {
        uuidAvd && uuidAvd !== ""
          ? store.dispatch("avd/getFindByUuidEvaluation", uuidAvd)
          : store.commit("avd/setIsShowStepper", false);
      } else {
        store.commit("avd/setIsShowStepper", false);
      }
    });

    // Variables
    const image = ref(logo);
    const tiposAvaliacoes = ref([]);
    const listaResponsaveis = ref([]);
    const listaClientes = ref([]);
    const isStartDateMenuOpen = ref(false);
    const isEndDateMenuOpen = ref(false);
    const listaTiposAvaliacoes = [
      { sigla: "AA", name: "Autoavaliação" },
      { sigla: "AP", name: "Avaliação de Pares" },
      { sigla: "AS", name: "Avaliação de Subordinado" },
      { sigla: "AL", name: "Avaliação de Líder" },
      { sigla: "AC", name: "Avaliação de Clientes" },
      { sigla: "TC", name: "Avaliação técnica" },
    ];
    const listaTiposEscalas = [
      { sigla: "SE", name: "Sempre...Nunca ou raramente" },
      { sigla: "CC", name: "Concordo...Discordo" },
    ];
    const activeStep = ref("0");
    const componentRender = ref(null);
    const dialogFinishEvaluation = ref(false);

    // Computed
    const avaliacao = computed(() => store.getters["avd/getAvaliacao"]);
    const isShowStepper = computed(() => store.getters["avd/getIsShowStepper"]);
    const tabsAvaliacaoForm = computed(() => store.getters["avd/getTabsForm"]);
    const tabFormSelected = computed({
      get() {
        return store.getters["avd/getTabFormSelected"];
      },
      set(value) {
        store.dispatch("avd/setTabFormSelected", value);
      },
    });
    /**
     * @description Retorna o Length de tabs que tiverem o disabled false
     *
     * @return Number
     */
    const tabsAvaliacaoFormLength = computed(() => {
      if (tabsAvaliacaoForm.value.length == 0) return 0;

      const tabsAvaliacaoDisabledFalse = tabsAvaliacaoForm.value.filter(
        (el) => {
          if (el.disabled == false) return el;
        }
      );

      return tabsAvaliacaoDisabledFalse.length;
    });
    /**
     * @description Retorna um array das tabs filtradas pelo disabled. Caso o disabled esteja FALSE, as mesmas serão retornadas.
     *
     * @return Array
     */
    const tabsAvaliacaoFormFiltred = computed(() => {
      if (tabsAvaliacaoForm.value.length == 0) return [];

      const tabsAvaliacaoDisabledFalse = tabsAvaliacaoForm.value.filter(
        (el) => {
          if (el.disabled == false) return el;
        }
      );

      return tabsAvaliacaoDisabledFalse;
    });
    const dateInitialFormatted = computed(() => {
      return avaliacao.value.dataInicio
        ? moment(avaliacao.value.dataInicio).format("L")
        : "";
    });
    const dateFinishFormatted = computed(() => {
      return avaliacao.value.dataFinal
        ? moment(avaliacao.value.dataFinal).format("L")
        : "";
    });

    // Function
    function validDateCurrent() {
      /**
       * NOTE
       * REGRA NEGOCIO: A data inicial deve ser sempre 7 dias aṕos a data atual
       * REGRA NEGOCIO: A data final deve ser sempre x dias aṕos a data inicial
       */
      return getLaterDate(7);
    }
    function validDateCurrentFinish() {
      /**
       * NOTE
       * REGRA NEGOCIO: A data inicial deve ser sempre 7 dias aṕos a data atual
       * REGRA NEGOCIO: A data final deve ser sempre x dias aṕos a data inicial
       */
      return setDayLaterDate(avaliacao.value.dataInicio, 7);
    }
    /**
     * @description Alterna os steps
     *
     * @param direction - Boolean
     */
    async function walkSteps(direction = true) {
      if (direction) {
        const result = await componentRender.value.validate();

        if (result == false) return false;
        tabFormSelected.value = tabFormSelected.value + 1;
      } else {
        tabFormSelected.value = tabFormSelected.value - 1;
      }
    }
    function iconSelectedTypeAvaliation() {
      if (listaTiposAvaliacoes && tiposAvaliacoes) {
        if (listaTiposAvaliacoes.length == tiposAvaliacoes.value.length)
          return "mdi-close-box";
        if (tiposAvaliacoes.value.length > 0) return "mdi-minus-box";
      }
      return "mdi-checkbox-blank-outline";
    }
    function toggleSelectTypeAvaliation() {
      listaTiposAvaliacoes.length == tiposAvaliacoes.value.length
        ? (tiposAvaliacoes.value = [])
        : (tiposAvaliacoes.value = listaTiposAvaliacoes.map((t) => t.sigla));
    }
    function isUpdateValidateTab(item) {
      if (item) {
        store.commit("avd/setTabFormSelected", item);
      }
    }
    function finish(openModal = false) {
      if (openModal) {
        dialogFinishEvaluation.value = true;

        return;
      }

      store.dispatch("avd/finishEvaluation", avaliacao.value.uuid);

      store.dispatch("avdTecnicas/resetDatas");
      store.dispatch("avd/resetAvdComportamentalData");
      store.dispatch("avd/resetAvaliacao");
      store.dispatch("avd/resetAvdTecnicaData");

      tabFormSelected.value = 1;
      this.$router.push({ name: "avd-avaliacoes-view" });
    }
    // Formata a data para o padrão do date picker
    function parseDate(dates) {
      if (!dates) return null;
      const [day, month, year] = dates.split("/");

      return `${year}-${month}-${day}`;
    }

    return {
      // Variables
      image,
      tiposAvaliacoes,
      listaResponsaveis,
      listaClientes,
      isStartDateMenuOpen,
      isEndDateMenuOpen,
      listaTiposAvaliacoes,
      listaTiposEscalas,
      activeStep,
      componentRender,
      validators: {
        required,
        emailValidator,
      },
      dialogFinishEvaluation,

      // Computed
      avaliacao,
      isShowStepper,
      tabsAvaliacaoForm,
      tabFormSelected,
      tabsAvaliacaoFormLength,
      tabsAvaliacaoFormFiltred,
      dateInitialFormatted,
      dateFinishFormatted,

      // Functions
      validDateCurrent,
      validDateCurrentFinish,
      walkSteps,
      iconSelectedTypeAvaliation,
      toggleSelectTypeAvaliation,
      isUpdateValidateTab,
      finish,
      parseDate,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "~@core/preset/preset/mixins.scss";

@include theme--child(delivery-options) using ($material) {
  border: 1px solid map-deep-get($material, "dividers");
  padding: 2rem;

  &.active {
    border-color: var(--v-primary-base);
  }
}
</style>

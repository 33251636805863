import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"380"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5"},[_vm._v(" Impossibilidade de edição! ")]),_c(VCardText,[_c('span',{staticClass:"error--text"},[_vm._v("Atenção!")]),_vm._v(" Ao finalizar a avaliação, ela não poderá mais ser editada. ")]),_c(VCardText,[_vm._v(" Deseja continuar com está ação? ")]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"secondary","text":""},on:{"click":function($event){return _vm.$emit('close-dialog')}}},[_vm._v(" Fechar ")]),_c(VBtn,{attrs:{"color":"success","text":""},on:{"click":function($event){return _vm.$emit('finish')}}},[_vm._v(" Finalizar avaliação ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }